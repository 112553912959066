<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>    
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0">
                    <v-breadcrumbs :items="itemsLinks">
                        <template v-slot:divider>
                            <v-icon>chevron_right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="center pb-0">
                    <div class="margen d-flex">
                        <hr class="vr" />
                        <p class="tblName pt-2">Detalle de timbres ilimitados</p>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card class="elevation-0 border-card">
                        <v-card-text style="overflow:auto" class="pa-10">
                            <div class="contenedor-card">
                                <h5 class="titulo-card">Datos</h5> 
                            </div>
                            <v-row class="ma-0 pa-0 pl-3">
                                <table>
                                    <tr >
                                        <td class="texto-estilo-gris pr-6">Cliente</td>
                                        <td class="texto-estilo-azul">{{ registroTimbreIlimitado.cliente.nombre_cliente }}</td>
                                    </tr>
                                    <tr>
                                        <td class="texto-estilo-gris pr-6">Razon social</td>
                                        <td class="texto-estilo-azul">{{registroTimbreIlimitado.datosFacturacion.razon_social}}</td>
                                    </tr>
                                    <tr>
                                        <td class="texto-estilo-gris pr-6">RFC</td>
                                        <td class="texto-estilo-azul">{{registroTimbreIlimitado.datosFacturacion.rfc}}</td>
                                    </tr>
                                    <tr>
                                        <td class="texto-estilo-gris pr-6">USO CFDI</td>
                                        <td class="texto-estilo-azul">{{registroTimbreIlimitado.datosFacturacion.uso_cfdi}}</td>
                                    </tr>
                                    <tr>
                                        <td class="texto-estilo-gris pr-6">Régimen Fiscal</td>
                                        <td class="texto-estilo-azul">{{registroTimbreIlimitado.datosFacturacion.regimen}}</td>
                                    </tr>
                                </table>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="mt-7">
                    <simple-table
                        ref="tabla"
                        :isSelectable="false"
                        :rows="datosTabla"
                        :columns="columns"
                        :perPage="[10, 25, 50, 100, 100]"
                        :tableName="'Historial de facturas de timbres'"
                    >
                        <tbody slot="body" slot-scope="{ data }" class="tablaBody">
                            <tr :key="factura.id" v-for="factura in data" class="alto-tr">
                                <td>
                                    <div>
                                        {{factura.descripcion_timbres}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{factura.mes_acomulacion}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{dateFormat(factura.fecha_emision)}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <div :class="classEstatus(factura.estatus)">
                                            <span class="classEstatus">
                                                <img
                                                    v-if="factura.estatus === 'Autorizado'"
                                                    style="height: 20px; width: 20px;  background-color: transparent;"
                                                    src="/static/icon/aprovado.png"
                                                    alt="Empleado"
                                                />
                                                <img
                                                    v-else-if="factura.estatus === 'Rechazado'"
                                                    style="height: 20px; width: 20px;  background-color: transparent;"
                                                    src="/static/icon/cancelado.png"
                                                    alt="Empleado"
                                                />
                                                <img
                                                    v-else-if="factura.estatus === 'EnProceso'"
                                                    style="height: 20px; width: 20px;  background-color: transparent;"
                                                    src="/static/icon/enProceso.png"
                                                    alt="Empleado"
                                                />
                                                &nbsp;&nbsp;&nbsp;
                                                {{factura.estatus == 'EnProceso'? 'En Proceso': factura.estatus}}

                                            </span>
                                        </div>
                                        
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <v-tooltip bottom v-if="rol == 'root'">
                                            <template v-slot:activator="{ on }">
                                                <v-btn 
                                                    v-on="on" 
                                                    class="botonHover" 
                                                    icon 
                                                    fab 
                                                    dark 
                                                    small 
                                                    @click="abrirModal(factura)"
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="rol == 'root'">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover"
                                                    v-if="factura.url_comprobante"
                                                    icon
                                                    fab
                                                    dark
                                                    @click="descargar(factura)"
                                                    small
                                                >
                                                    <v-icon
                                                        v-text="'$get_app_outline'"
                                                        class="iconoDelgadoBoton"
                                                    ></v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Descargar comprobante de transferencia</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Estatus" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    :items="itemsEstatus"
                                                    item-text="descripcion"
                                                    item-value="valor"
                                                    label="Estatus"
                                                    persistent-hint
                                                    v-model="estatus"
                                                    :error-messages="errors"
                                                    no-data-text="Datos no disponibles"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import apiConfiguracionTimbresIlimitados from '@/api/nominas/configurarTimbresIlimitados';
import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";

export default {
    components: {
        'simple-table': DatatableSimple,
    },
    data(){
        return {
            idRegistroTimbreIlimitado: null,
            datosLogin: null,
            rol: null,
            itemsLinks      : [
                {
                    text: 'Configuración de timbres ilimitados',
                    disabled: false,
                    to: 'timbresIlimitados',
                },
                {
                    text: 'Detalle de timbres ilimitados',
                    disabled: true,
                    to: ''
                },
            ],
            datosTabla  : [],
            columns             : [
                {
                    label: 'Descripción',
                    name: 'descripcion',
                    filterable: false
                },
                {
                    label:'Mes de acumulación',
                    name:'mes_acomulacion',
                    filterable: false
                },
                {
                    label: 'Fecha de emisión',
                    name: 'fecha_solicitud',
                    filterable: false
                },
                {
                    label: 'Estatus',
                    name: 'estatus',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    filterable: false
                }
            ],
            isLoadingModal  : false,
            dialog          : false,
            tituloModal     : '',
            imagen          : '',
            estatus         : null,
            itemsEstatus    : [
                { valor: 'Autorizado', descripcion: 'Autorizado' },
                { valor: 'Rechazado', descripcion: 'Rechazado' },
                /* { valor: 'EnProceso', descripcion: 'En Proceso' } */
            ],
            facturaId       : null,
            isSaving        : false,
        }
    },
    watch:{
        
    },
    computed:{

    },
    methods:{
        getHistorial(){
            apiConfiguracionTimbresIlimitados.getHistorial(this.registroTimbreIlimitado.datosFacturacion.id).then(response => {
                this.datosTabla = response.data.historico;
            })
            .catch(error =>{
                console.log(error)
            })
        },

        /**
         * @method dateFormat Esta método parsea una fecha
         * @description Convierte una fecha de formato AAAA-MM-DD => DD/MM/AAAA. Se usa para el modal y la tabla 
         * @param {string} fecha formato AAAA-MM-DD
         * @return {string} devuelve un string en formato DD/MM/AAAA
        */
        dateFormat(fecha) {
            if(fecha == 'null' || fecha == ""){
                return
            }

            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },

        classEstatus(estatus){
            if (estatus == "Autorizado") return "classAutorizado";
            if (estatus == "Rechazado") return "classRechazado";
            if (estatus == "EnProceso") return "classProceso";

        },
        abrirModal(data = null){
            this.isLoadingModal     = true;
            this.tituloModal        = "Actualizar factura";
            this.imagen             = "/static/modal/brigadasUpdate.svg";
            this.isLoadingModal     = false;
            this.dialog =  true;
            this.facturaId = data.id
            if(data.estatus === 'EnProceso'){
                this.estatus = null;
            }
            else{
                this.estatus = data.estatus;
            }
        },
        cerrarModal(){
            this.dialog = false;
            this.facturaId  = null;
            this.estatus = null;
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        guardar(){
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                let parametros = {
                    id : this.facturaId,
                    accion : this.estatus
                }
                apiConfiguracionTimbresIlimitados.actualizarEstatus(parametros).then(response => {
                    Notify.Success("Registro Actualizado", "El registro ha sido satisfactoriamente.");
                    this.cerrarModal();
                    this.getHistorial();
                    this.isSaving = false;
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });

            });
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        this.registroTimbreIlimitado = this.$session.get('registroTimbreIlimitado');
    },
    mounted(){
        this.getHistorial();
    },
    updated(){

    },
}
</script>

<style scoped>
    .margen {
		height: 48px;
		margin-bottom: 20px;
		margin-left: 1px;
	}
    .border-card {
        border-radius: 1.25rem;
    }
    .contenedor-card{
        padding: .625rem; /* 10px */
    }
    .titulo-card {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 1.125rem; /* 18px */
        line-height: 1.375rem; /* 22px */
        color: #444975;
    }
    .texto-estilo-gris{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: .875rem; /* 14px */
        line-height: 1.0625rem; /* 17px */
        color: #828282;
    }
    .texto-estilo-azul{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: .875rem;
        line-height: 1.0625rem;
        color: #444975;
    }
    .texto-titulo {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #1E2144;
    }
    .classEstatus {
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .classAutorizado{
        color: #6FCF97;
        border: 1px solid #6FCF97;
        border-radius: 50px;
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .classRechazado {
        color: #D93025;
        border: 1px solid #D93025;
        border-radius: 50px;
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .classProceso {
        color: #E6B216;
        border: 1px solid #E6B216;
        border-radius: 50px;
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>